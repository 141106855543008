#leftMenu {
    .list-group-item {
        border-left: 2px solid $color-main;
        border-right: none;
        border-bottom: none;
        border-top: none;
        margin-bottom: 7px;
        border-radius: 0;
        transition: all 0.3s;
        &:hover {
            text-decoration: none;
            background-color: $color-main-hover;
        }

        i {
            position: absolute;
            right: 10px;
        }
    }

    .list-group-item-sub {
        padding-left: 10px;
    }

    .active {
        background-color: $color-main-active;
    }

}

#social-links {

    margin-top: 50px;

    ul {
        list-style-type: none;
        padding: 10px;
        li {
            display: inline-block;
            width: 30%;
            border: 1px solid $color-main2;

            &:first-child {
                border-right: none;
            }
            a {
                font-size: 1.6rem;
                color: $color-main;
            }
        }
    }

}

.page-custom {
    .page-custom-link {
        display: block;
        width: 100%;
        margin-left: 20px;
        margin-right: 20px;
        color: #fff;
        background-color: $color-main;
        &:hover {
            text-decoration: none;
            background-color: $color-main-hover;
        }
    }

}

.content-page-about {
    h4 {
        border-bottom: 2px solid $color-main;
    }

    ul {
        padding-left: 15px;
    }
}
