// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$font-title-color: #000;
$font-date-color: #6b6b6b;
$font-date-size: 14px;

$color-main: #FBA710;
$color-main2: #facc80;
$color-main-active: #facc80;
$color-main-hover: #EEAD36;

//$navbar-light-color: rgba(#000, .5);
$navbar-light-color: rgba($body-bg, 1);

// Bootstrap
// - Carousel
$carousel-control-color: $color-main;
