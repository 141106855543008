@font-face {
    font-family: 'Myriad';
    src: url('/fonts/MyriadPro-Regular.ttf');

}

@font-face {
    font-family: 'MyriadLight';
    src: url('/fonts/MyriadPro-Light.ttf');
}

html, body {
    //font-family: Myriad, Helvetica, Arial,  sans-serif;
}

.header
{
    .logo {
        a {
            font-family: Myriad, Helvetica, Arial,  sans-serif;
            display: block;
            width: 100px;
            //height: 65px;
            padding-bottom: 8px;
            background-image: url('/img/logo.png');
            background-repeat: no-repeat;
            //padding-top: 56px;
            padding-left: 100px;
            font-size: 18pt;
            font-weight: 700;
            text-transform: uppercase;
            color: black;
            height: 110px;
            padding-top: 50px;
            line-height: 1.2;
            &:hover {
                text-decoration: none;
            }
            span {
                display: none;
            }
        }

        // Small devices (landscape phones, 576px and up)
        @media (min-width: 576px) {
            a {
                //width: 365px;
                width: 100%;
                height: auto;
                span {
                    display: inherit;
                }
            }
        }
    }

    padding-top: 20px;

    background-image: url('/img/header_bg_3.png');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% auto;

    .social_icons {

        position: absolute;
        right: 10px;
        top: 60px;

        .social_icon {
            display: inline-block;
            width: 32px;
            height: 32px;
        }

        .social_facebook {
            background-image: url('/img/social/facebook_icon.svg');
        }

        .social_twitter {
            margin-left: 5px;
            background-image: url('/img/social/twitter_icon.svg');
        }
    }

    .languages {
        position: absolute;
        right: 10px;
        top: 10px;
        a {
            display: inline-block;
            margin-left: 2px;
            margin-right: 2px;
            padding: 3px;
            font-weight: 700;
            background-color:rgba(251, 167, 16, 0.2);
            border-radius: 2px;
            transition: all 0.3s;
            &:hover {
                text-decoration: none;
                background-color:rgba(251, 167, 16, 0.4);
            }
            &.active {
                border: 3px solid $color-main;
            }
        }
    }
}

.nav {
    margin-top: 10px;
    margin-bottom: 15px;
    background-color: #FBA710;

    a {
        color: #ffffff;
    }

}

.menu-top {
    background-color: #FBA710;
    .navbar {
        padding: 0;
        .nav-item {
            //margin-left: 10px;
            //margin-right: 10px;
        }
        a {
            color: #ffffff!important;
            font-size: 16px;
        }

        .navbar-toggler {
            color: rgb(255, 255, 255);
            border-color: rgba(255, 255, 255, 0.7);
        }
    }

    .nav-search-block-menu {
        position: absolute;
        right: 0;
        bottom: 0;
        .nav-search-block_link_open {

            font-size: 16px;
            //transition: all 0.3s;
            span {
                transition: all 0.3s;
            }

            i {
                margin-right: 5px;
                color: #ffffff;
                font-size: 20px;
                transition: all 0.3s;
            }
            &:hover {
                text-decoration: none;
                span {
                    color: #a1a1a1;
                }

                i {
                    color: #a1a1a1;
                }
            }
        }
    }

    // Small devices (landscape phones, 576px and up)
    @media (min-width: 576px) {
        .nav-search-block-menu {
            bottom: inherit;
        }
    }
}

.content-block {
    position: relative;

    .block-search {
        display: none;
        position: absolute;
        top: 0;
        z-index: 11;

        .block-search_link {
            position: absolute;
            right: 10px;
            top: 15px;

            a {
                color: #ffffff;
                transition: all 0.3s;

                i {
                    font-size: 20px;
                    transition: all 0.3s;
                }

                &:hover {
                    text-decoration: none;
                    span {
                        color: #a1a1a1;
                    }

                    i {
                        color: #a1a1a1;
                    }
                }
            }
        }
    }
}

.content-item {
    .page-title {
        h1 {
            font-size: 1.6rem;
            font-weight: 600;
        }

    }
    .page-date {
        color: $font-date-color;
        font-size: $font-date-size;
    }
}



.footer {
    background-color: #c2c2c2;

    .footer_menu {
        border-radius: 0;
        .list-group-item {
            background-color: inherit;
            border: none;
            padding: 0.2rem 1.25rem;
        }
    }

}
