a {
    color: #231f20;
    &:hover {
        color: #231f20;
        text-underline: none;
    }
}

.btn-primary {
    background-color: $color-main;
    border-color: $color-main;
    &:hover {
        background-color: $color-main-active;
        border-color: $color-main-active;
    }
}

.pagination {
    .active {
        span.page-link {
            background-color: $color-main;
            border-color: $color-main;
        }
    }
    a.page-link {
        color: #000;
    }
}

.breadcrumb {
    background-color: inherit;
    padding: 0.75rem 0;

    .breadcrumb-item:first-child {
        border-left: 2px solid $color-main;
        padding-left: 5px;
    }
    .breadcrumb-item {
        line-height: 1.1;
    }

}
