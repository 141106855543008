.content-carousel {
    .carousel-caption{
        text-align: left;
        left: inherit;
        right: inherit;
        bottom: 0;
        background-color: rgba(27, 30, 33, 0.44);
        transition: all 0.3s;
        a {
            color: #fff;
            display: block;
            &:hover {
                text-decoration: none;
            }

            h5 {
                margin-left: 60px;
                margin-right: 60px;
            }
        }
        &:hover {
            background-color: rgba(27, 30, 33, 0.6);
            a {
                .btn-primary {
                    background-color: #facc80;
                    border-color: #facc80;
                }
            }
        }
    }

    .carousel-control-prev {
        left: -40px;
    }

    .carousel-control-next {
        right: -40px;
    }

    .carousel-control-prev, .carousel-control-next {
        width: 4%;
        border: none;
        background: none;
        opacity: 1;
    }
}


.content_main {

    .block-title {
        color: $font-title-color;
        font-size: 18px;
        font-weight: bold;
        padding-left: 10px;
        border-left: 10px solid $color-main;
    }

    .block-item-link {
        display: block;
        transition: all 0.3s;
        &:hover {
            text-decoration: none;
            //background-color: rgba(251, 167, 16, 0.1);
            .block-item-image {
                img {
                    transform: scale(1.05);
                }
            }
        }
    }

    .block-item {

        .block-item-image {

            //background-repeat: no-repeat;
            //background-size: 100% auto;

            .block-item-image-wrapper {
                overflow: hidden;
                min-height: 80px;
                //max-height: 80px;
                margin-left: 3px;
                margin-top: 5px;
                text-align: center;
                img {
                    transition: all 0.3s;
                }
            }
        }

        .block-item-content {
            .block-item-title {
                font-weight: bold;
                color: $font-title-color;
                font-size: 16px;
                line-height: 1.3;
            }

            .block-item-date {
                color: $font-date-color;
                font-size: $font-date-size;
            }
        }
    }
}

.content-carousel {
    .carousel-item-2 {
        height: 200px;
        background-size: 100% auto;
    }
}
