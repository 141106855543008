.error-page {
    .error_not-found-code {
        //color: rgba(255,255,255,0.1);
        color: #C2C2C2;
        letter-spacing: 8px;
        font-size: 100px;
        line-height: 1em;
        font-weight: 600;
        margin-bottom: 40px;
    }
    .error_not-found-description {
        font-size: 32px;
        line-height: 1em;
        font-weight: 600;
        margin-bottom: 40px;
    }
    .error_not-found-proposition
    {
        font-size: 20px;
        line-height: 1em;
    }
}
